import PropTypes from "prop-types";
import { Component } from "react";

import h from "h";
import { fieldType } from "./PropTypes";
import LabelledField from "./LabelledField";
import ErrorContainer from "./ErrorContainer";
import toFieldProps from "./toFieldProps";
import { textarea } from "tags";

class TextAreaField extends Component {
  static propTypes = {
    width: PropTypes.number,
    label: PropTypes.string,
    rows: PropTypes.number,
    field: fieldType,
    help: PropTypes.node,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool
  };

  render() {
    const { width, label, help, field, rows, autoComplete, autoFocus } = this.props;

    return h(LabelledField, { width, label, help }, [
      h(ErrorContainer, { field }, [
        h(textarea, {
          ...toFieldProps(field),
          rows,
          className: "form-input",
          autoComplete,
          autoFocus
        })
      ])
    ]);
  }
}

export default TextAreaField;
